import { Button } from 'components/shadcn/button';
import { Card } from 'components/shadcn/card';
import { Conversation } from 'components';
import { MessageCircle } from 'lucide-react';
import { UpdateChatForm } from 'pages/dashboard';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchWithAuth } from 'utils';
import { apiUrl } from 'utils/constants';

export const EditChat = () => {
  const [name, setName] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchName = async (id: string) => {
    const response = await fetchWithAuth(`${apiUrl}/chat-metadata/${id}`);
    const jsonData = await response.json();
    setName(jsonData.name);
  };

  useEffect(() => {
    try {
      if (!id) {
        setName('unknown');
        return;
      }
      fetchName(id);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  });

  return (
    <div className="w-full p-8">
      <div className="flex flex-row mb-4 items-center justify-between">
        <h1 className="text-4xl font-black text-secondary">{name}</h1>
        <Button variant="secondary" onClick={() => navigate(`/${id}`)}>
          <MessageCircle className="mr-2 h-4 w-4" /> Chat with Bot
        </Button>
      </div>
      <div className="flex gap-4 w-full">
        <div className="flex-1 min-w-0 w-2/3">
          <UpdateChatForm />
        </div>
        <div className="w-1/3">
          <Card className="rounded-xl sticky top-8 max-h-[calc(100vh-64px)] h-full overflow-y-auto border border-border">
            <Conversation playgroundMode />
          </Card>
        </div>
      </div>
    </div>
  );
};
