import { Button } from 'components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/dialog';
import { Input } from 'components/shadcn/input';
import { Label } from 'components/shadcn/label';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shadcn/table';
import { Pencil, Plus, Trash2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ModelMetadata } from 'types';
import { apiUrl, fetchWithAuth } from 'utils';

export const ModelMetadataList = () => {
  const [models, setModels] = useState<ModelMetadata[]>([]);
  const [selectedModel, setSelectedModel] = useState<ModelMetadata | null>(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Form state
  const [dialogName, setDialogName] = useState('');
  const [dialogDisplayName, setDialogDisplayName] = useState('');
  const [dialogInputExchangeRate, setDialogInputExchangeRate] = useState('0');
  const [dialogOutputExchangeRate, setDialogOutputExchangeRate] = useState('0');

  const isEditing = !!selectedModel && !isCreating;

  const fetchModels = async () => {
    try {
      const response = await fetchWithAuth(`${apiUrl}/model-metadata`);
      const data = await response.json();
      setModels(data);
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  useEffect(() => {
    fetchModels();
  }, []);

  useEffect(() => {
    if (selectedModel) {
      setDialogName(selectedModel.name);
      setDialogDisplayName(selectedModel.displayName);
      setDialogInputExchangeRate(selectedModel.inputExchangeRate.toString());
      setDialogOutputExchangeRate(selectedModel.outputExchangeRate.toString());
    } else {
      setDialogName('');
      setDialogDisplayName('');
      setDialogInputExchangeRate('0');
      setDialogOutputExchangeRate('0');
    }
  }, [selectedModel]);

  const handleDelete = async (id: number) => {
    if (!window.confirm('Are you sure you want to delete this model?')) {
      return;
    }

    try {
      await fetchWithAuth(`${apiUrl}/model-metadata/${id}`, {
        method: 'DELETE',
      });

      // Update the local state to reflect the deletion
      setModels(models.filter((model) => model.id !== id));
    } catch (error) {
      console.error('Error deleting model:', error);
    }
  };

  const submitDialog = async () => {
    setIsLoading(true);

    try {
      const payload = {
        name: dialogName,
        displayName: dialogDisplayName,
        inputExchangeRate: parseFloat(dialogInputExchangeRate),
        outputExchangeRate: parseFloat(dialogOutputExchangeRate),
      };

      let response;

      if (isCreating) {
        response = await fetchWithAuth(`${apiUrl}/model-metadata`, {
          method: 'POST',
          body: JSON.stringify(payload),
        });
      } else {
        response = await fetchWithAuth(`${apiUrl}/model-metadata/${selectedModel?.id}`, {
          method: 'PUT',
          body: JSON.stringify(payload),
        });
      }

      if (!response.ok) {
        throw new Error('Failed to save model');
      }

      // Refresh the model list
      await fetchModels();

      // Close the dialog
      setSelectedModel(null);
      setIsCreating(false);
    } catch (error) {
      console.error('Error saving model:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-auto py-6 px-4 lg:px-6 lg:max-w-screen-lg flex-grow self-start box-border w-full">
      <div className="flex flex-row gap-2 justify-between items-center mb-10">
        <h1 className="text-4xl font-black text-secondary">MODEL METADATA</h1>
        <Button variant="secondary" color="success" onClick={() => setIsCreating(true)}>
          <Plus className="mr-2 h-4 w-4" /> Create Model
        </Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>ID</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Display Name</TableHead>
              <TableHead>Input Exchange Rate</TableHead>
              <TableHead>Output Exchange Rate</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {models.map((model) => (
              <TableRow key={model.id}>
                <TableCell>{model.id}</TableCell>
                <TableCell>{model.name}</TableCell>
                <TableCell>{model.displayName}</TableCell>
                <TableCell>{model.inputExchangeRate}</TableCell>
                <TableCell>{model.outputExchangeRate}</TableCell>
                <TableCell className="flex gap-2">
                  <Button variant="ghost" size="icon" onClick={() => setSelectedModel(model)}>
                    <Pencil className="h-4 w-4" />
                  </Button>
                  <Button variant="ghost" size="icon" onClick={() => handleDelete(model.id)}>
                    <Trash2 className="h-4 w-4 text-destructive" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog
        open={isCreating || isEditing}
        onOpenChange={() => {
          setSelectedModel(null);
          setIsCreating(false);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{isCreating ? 'Create Model' : 'Edit Model'}</DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-4 max-h-[500px] overflow-y-auto p-4">
            <div>
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                value={dialogName}
                onChange={(e) => setDialogName(e.target.value)}
                placeholder="gpt-4-turbo"
              />
            </div>
            <div>
              <Label htmlFor="displayName">Display Name</Label>
              <Input
                id="displayName"
                value={dialogDisplayName}
                onChange={(e) => setDialogDisplayName(e.target.value)}
                placeholder="GPT-4 Turbo"
              />
            </div>
            <div>
              <Label htmlFor="inputExchangeRate">Input Exchange Rate</Label>
              <Input
                id="inputExchangeRate"
                type="number"
                step="0.000001"
                value={dialogInputExchangeRate}
                onChange={(e) => setDialogInputExchangeRate(e.target.value)}
                placeholder="0.00001"
              />
            </div>
            <div>
              <Label htmlFor="outputExchangeRate">Output Exchange Rate</Label>
              <Input
                id="outputExchangeRate"
                type="number"
                step="0.000001"
                value={dialogOutputExchangeRate}
                onChange={(e) => setDialogOutputExchangeRate(e.target.value)}
                placeholder="0.00003"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="ghost"
              onClick={() => {
                setSelectedModel(null);
                setIsCreating(false);
              }}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={submitDialog}
              disabled={isLoading || !dialogName || !dialogDisplayName}
              variant="secondary"
            >
              {isLoading ? 'Saving...' : isCreating ? 'Create' : 'Save'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModelMetadataList;
