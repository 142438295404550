import Editor from '@monaco-editor/react';
import { useState } from 'react';
import { useFieldArray, useFormContext, Controller } from 'react-hook-form';
import { apiUrl } from '../utils/constants';
import { UpdateForm } from '../types';
import { fetchWithAuth } from 'utils';
import { PlusCircle } from 'lucide-react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/shadcn/accordion';
import { Button } from 'components/shadcn/button';
import { Input } from 'components/shadcn/input';
import { Label } from 'components/shadcn/label';
import { Textarea } from 'components/shadcn/textarea';

export const FunctionRepeater = () => {
  const [expanded, setExpanded] = useState<string | null>(null);
  const { control, watch } = useFormContext<UpdateForm>();

  const { fields, insert, remove } = useFieldArray({
    control,
    keyName: 'fieldId',
    name: 'functions',
  });

  const handleAccordionChange = (value: string) => {
    setExpanded(expanded === value ? null : value);
  };

  const removeFunction = async (id: number | undefined, index: number) => {
    try {
      const response = await fetchWithAuth(`${apiUrl}/chat-function/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status !== 200) {
        throw new Error('Something went wrong!');
      }
      remove(index);
    } catch (error) {
      console.error(error);
    }
  };

  const functionWatch = watch('functions');

  return (
    <div className="space-y-4">
      <Accordion
        type="single"
        collapsible
        value={expanded || undefined}
        onValueChange={handleAccordionChange}
        className="space-y-2"
      >
        {fields.length > 0 &&
          fields.map((field, index) => (
            <AccordionItem
              key={field.fieldId}
              value={`panel${index}`}
              className="border rounded-md overflow-hidden shadow-sm bg-white"
            >
              <AccordionTrigger className="px-4 py-2 hover:bg-slate-50">
                <div className="flex items-center">
                  <span className="font-mono italic mr-2">𝑓</span>
                  {functionWatch && functionWatch[index] && functionWatch[index].name && (
                    <span className="text-sm font-medium">{functionWatch[index].name}</span>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="px-4 pt-2 pb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="col-span-1">
                    <Label htmlFor={`functions.${index}.name`} className="mb-2 block">
                      Name
                    </Label>
                    <Controller
                      name={`functions.${index}.name`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <div className="space-y-1">
                          <Input
                            id={`functions.${index}.name`}
                            {...field}
                            className="w-full"
                            placeholder="Function name"
                          />
                          {error && <p className="text-sm text-destructive">{error.message}</p>}
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="space-y-4">
                  <div>
                    <Label htmlFor={`functions.${index}.description`} className="mb-2 block">
                      Description
                    </Label>
                    <Controller
                      name={`functions.${index}.description`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <div className="space-y-1">
                          <Textarea
                            id={`functions.${index}.description`}
                            {...field}
                            className="w-full min-h-[120px]"
                            placeholder="Function description"
                          />
                          {error && <p className="text-sm text-destructive">{error.message}</p>}
                        </div>
                      )}
                    />
                  </div>

                  <div>
                    <Label htmlFor={`functions.${index}.parameters`} className="mb-2 block">
                      Parameters
                    </Label>
                    <Controller
                      name={`functions.${index}.parameters`}
                      control={control}
                      render={({ field }) => (
                        <div className="border rounded-md overflow-hidden">
                          <Editor
                            value={field.value ? field.value : '{}'}
                            defaultLanguage="json"
                            height="200px"
                            options={{
                              minimap: { enabled: false },
                              scrollBeyondLastLine: false,
                              lineNumbers: 'on',
                              scrollbar: { alwaysConsumeMouseWheel: false },
                            }}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>

                  <div>
                    <Label htmlFor={`functions.${index}.code`} className="mb-2 block">
                      Code
                    </Label>
                    <Controller
                      control={control}
                      name={`functions.${index}.code`}
                      render={({ field }) => (
                        <div className="border rounded-md overflow-hidden">
                          <Editor
                            value={field.value}
                            defaultLanguage="javascript"
                            height="500px"
                            options={{
                              minimap: { enabled: false },
                              scrollBeyondLastLine: false,
                              lineNumbers: 'on',
                              scrollbar: { alwaysConsumeMouseWheel: false },
                            }}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>

                <div className="flex justify-end mt-4">
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => removeFunction(field.id, index)}
                  >
                    Remove Function
                  </Button>
                </div>
              </AccordionContent>
            </AccordionItem>
          ))}
      </Accordion>

      <div className="flex justify-center mt-4">
        <Button
          type="button"
          variant="outline"
          size="sm"
          onClick={() => insert(fields.length, {})}
          className="flex items-center gap-1"
        >
          <PlusCircle className="h-4 w-4" />
          <span>Add Function</span>
        </Button>
      </div>
    </div>
  );
};
