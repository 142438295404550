import { Credits } from 'components';
import { Button } from 'components/shadcn/button';
import { Card, CardContent } from 'components/shadcn/card';
import { RefreshCcw, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { MessageCard } from '../MessageCard';
import { ChatMetadata, Direction, Message, Sender } from '../types';
import { UserInput } from '../UserInput';
import { apiUrl, getRecaptchaToken, scrollToBottom, useLocalStorage } from '../utils';

export function Conversation({ playgroundMode = false }: { playgroundMode?: boolean }) {
  const [sessionID, setSessionID] = useLocalStorage('sessionID', uuidv4());

  const [chatMetadata, setChatMetadata] = useState<ChatMetadata>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { id } = useParams();

  // Direction will be handled with CSS classes rather than theme settings
  const direction = chatMetadata?.direction === Direction.RTL ? 'rtl' : 'ltr';

  useEffect(() => {
    if (!chatMetadata) return;
    if (chatMetadata.direction === Direction.RTL && !playgroundMode) {
      document.documentElement.setAttribute('dir', 'rtl');
    }
    document.title = chatMetadata.name;
    if (chatMetadata.conversations[0]?.messages.length) {
      for (const message of chatMetadata.conversations[0].messages) {
        appendMessages({
          sender: message.sender === 'ASSISTANT' ? Sender.Assistant : Sender.User,
          content: message.content,
        });
      }
    } else {
      setMessages([
        {
          sender: Sender.Assistant,
          content: chatMetadata.firstMessage,
        },
      ]);
    }
    return () => {
      document.documentElement.setAttribute('dir', 'ltr');
    };
  }, [chatMetadata]);

  useEffect(() => {
    getChatMetadata();
  }, [sessionID]);

  function appendMessages(newMessages: Message | Message[]) {
    setMessages((prevMessages) => [
      ...prevMessages,
      ...(Array.isArray(newMessages) ? newMessages : [newMessages]),
    ]);
  }

  async function sendMessage(text: string) {
    if (loading || !chatMetadata) {
      return;
    }
    setLoading(true);
    appendMessages({ sender: Sender.User, content: text });
    if (!playgroundMode) {
      scrollToBottom();
    }
    if (messages.length === 1) {
      await startConversation();
    }
    const result = await postMessage({
      message: text,
      sessionID,
    });
    appendMessages({ ...result, sender: Sender.Assistant });
    setLoading(false);
    if (!playgroundMode) {
      scrollToBottom();
    }
  }

  async function sendEditorMessage(text: string) {
    appendMessages({ sender: Sender.User, content: text });
    const payload = {
      chatId: id,
      conversation: messages.concat({ sender: Sender.User, content: text }),
    };
    setLoading(true);
    const result = await fetch(`${apiUrl}/web/conversation/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const json = await result.json();
    appendMessages({ sender: Sender.Assistant, content: json.content });
    setLoading(false);
  }

  async function startConversation() {
    await fetch(`${apiUrl}/web/start/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getRecaptchaToken()),
      },
      body: JSON.stringify({ sessionID }),
    });
  }

  async function postMessage(payload: { message: string; sessionID: string }): Promise<Message> {
    const result = await fetch(`${apiUrl}/web/message/${chatMetadata?.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getRecaptchaToken()),
      },
      body: JSON.stringify(payload),
    });
    return result.json();
  }

  async function getChatMetadata() {
    const result = await fetch(`${apiUrl}/web/${id}?sessionID=web:${sessionID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await getRecaptchaToken()),
      },
    });
    setChatMetadata(await result.json());
  }

  function deleteMessage(index: number) {
    setMessages((prev) => prev.filter((_, i) => i !== index));
  }

  const textFieldLabel =
    messages.length === 1 ? chatMetadata?.textFieldLabel ?? 'כתבו הודעה על מנת להתחיל...' : '';

  // Helper to determine message alignment class based on sender and direction
  const getMessageAlignmentClass = (message: Message): string => {
    const isRTL = chatMetadata?.direction === Direction.RTL;
    const isMessageFromAssistant = message.sender === Sender.Assistant;

    if (isRTL) {
      return isMessageFromAssistant ? 'self-start' : 'self-end';
    }

    return isMessageFromAssistant ? 'self-end' : 'self-start';
  };

  async function restartConversation() {
    setLoading(false);
    setSessionID(uuidv4());
  }

  if (!chatMetadata) {
    return null;
  }

  return (
    <div className={`h-full ${direction}`}>
      <div className="container mx-auto max-w-4xl h-full flex flex-col space-y-4">
        {playgroundMode && (
          <Button
            onClick={restartConversation}
            variant="outline"
            size="icon"
            className="absolute top-6 left-6"
          >
            <RefreshCcw className="h-4 w-4" />
          </Button>
        )}
        <div className="sticky top-0 left-0 right-0 h-8 bg-gradient-to-b from-background to-transparent" />

        <div className="flex flex-col space-y-6">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.sender === Sender.User ? 'flex-row' : 'flex-row-reverse'} items-start gap-2`}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className={getMessageAlignmentClass(message)}>
                <MessageCard message={message} showSources={chatMetadata?.showSources} />
              </div>
              {playgroundMode && (
                <Button
                  variant="ghost"
                  size="icon"
                  aria-label="delete"
                  onClick={() => deleteMessage(index)}
                  className={`opacity-${hoveredIndex === index ? '100' : '0'} transition-opacity duration-300`}
                >
                  <X className="h-4 w-4 text-muted-foreground" />
                </Button>
              )}
            </div>
          ))}
          {loading && (
            <div className="flex flex-row-reverse">
              <Card className="bg-muted border-0 shadow-none">
                <CardContent className="p-4">
                  <img src="/loading.gif" alt="loading" width={50} />
                </CardContent>
              </Card>
            </div>
          )}
        </div>

        <div className="flex-1" />

        <div className="sticky bottom-0 bg-background py-4 space-y-4">
          <UserInput
            label={textFieldLabel}
            onSend={playgroundMode ? sendEditorMessage : sendMessage}
            isLoading={loading}
            regainFocus
          />
          <Credits />
        </div>
      </div>
    </div>
  );
}
