import { Card, CardContent, CardTitle } from 'components/shadcn/card';
import { Brain, MessageCircleCode, SquareUserRound } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const FlowPicker: React.FC = () => {
  const navigate = useNavigate();

  const navigateToChats = () => {
    navigate('/dashboard/chats');
  };

  const navigateToUsers = () => {
    navigate('/dashboard/users');
  };

  return (
    <div className="justify-center align-center flex flex-row gap-6">
      <Card
        onClick={navigateToChats}
        className="w-48 h-48 flex flex-col align-center justify-center cursor-pointer"
      >
        <CardContent className="flex flex-col align-center justify-center text-center gap-4">
          <MessageCircleCode size={80} className="self-center text-primary" />
          <CardTitle className="text-secondary">Chats</CardTitle>
        </CardContent>
      </Card>
      <Card
        onClick={navigateToUsers}
        className="w-48 h-48 flex flex-col align-center justify-center cursor-pointer"
      >
        <CardContent className="flex flex-col align-center justify-center text-center gap-4">
          <SquareUserRound size={80} className="self-center text-primary" />
          <CardTitle className="text-secondary">Users</CardTitle>
        </CardContent>
      </Card>
      <Card
        onClick={() => navigate('/dashboard/models')}
        className="w-48 h-48 flex flex-col align-center justify-center cursor-pointer"
      >
        <CardContent className="flex flex-col align-center justify-center text-center gap-4">
          <Brain size={80} className="self-center text-primary" />
          <CardTitle className="text-secondary">Models</CardTitle>
        </CardContent>
      </Card>
    </div>
  );
};
