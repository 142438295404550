import { PaletteColorOptions } from '@mui/material';

export enum ChatModel {
  GPT_35 = 'GPT_35',
  GPT_35_16K = 'GPT_35_16K',
  GPT_4 = 'GPT_4',
  GPT_4_AZURE = 'GPT_4_AZURE',
  GPT_4_TURBO = 'GPT_4_TURBO',
  GPT_4O = 'GPT_4O',
  GPT_4O_MINI = 'GPT_4O_MINI',
  GPT_4O_2024_08_06 = 'GPT_4O_2024_08_06',
}

export enum AnswersModality {
  TEXT = 'TEXT',
  VOICE = 'VOICE',
  TEXT_AND_VOICE = 'TEXT_AND_VOICE',
}
export enum Role {
  ADMIN = 'ADMIN',
  EDITOR = 'EDITOR',
}
export interface Conversation {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  messages: Message[];
  username: string;
  metadata?: { [key: string]: unknown };
  extractedData?: { [key: string]: unknown };
}

export interface User {
  canEditDynamicPrompts: boolean;
  canEditDataToExtract: boolean;
  id: string;
  email: string;
  role: Role;
  chats: Pick<ChatMetadata, 'id' | 'name' | 'inputTokens' | 'totalMessages' | 'outputTokens'>[];
}

export interface DynamicSystemPrompt {
  id?: number;
  name: string;
  prompt: string;
  condition: string;
  zIndex: number;
}

export interface PromptVariable {
  id?: number;
  name: string;
  value: string;
  injectToStartOfSystemPrompt: boolean;
  injectToEndOfSystemPrompt: boolean;
  injectToStartOfDynamicPrompt: boolean;
  injectToEndOfDynamicPrompt: boolean;
}

export interface ModelMetadata {
  id: number;
  name: string;
  displayName: string;
  inputExchangeRate: number;
  outputExchangeRate: number;
  isArchived: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum Direction {
  LTR = 'LTR',
  RTL = 'RTL',
}

export enum Sender {
  User = 'user',
  Assistant = 'assistant',
}

export interface ExtractedDataField {
  id?: number;
  name: string;
  description: string;
  dataType: DataType;
}

export enum DataType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
}

export interface ChatMetadata {
  firstMessage: string;
  id: string;
  name: string;
  direction: Direction;
  colors: PaletteColorOptions;
  textFieldLabel: string;
  showSources: boolean;
  messagesInMemory: number;
  inputTokens: number;
  outputTokens: number;
  totalMessages: number;
  conversations: [
    {
      messages: {
        sender: 'USER' | 'ASSISTANT';
        content: string;
      }[];
    },
  ];
  costs?: number | null;
  promptVariables?: PromptVariable[];
  modelMetadata?: ModelMetadata;
}

export interface Message {
  id?: string;
  createdAt?: string;
  sender: Sender;
  content: string;
  sources?: Source[];
}

export interface Source {
  title: string;
  url: string;
}

export interface ChatFunction {
  id?: number;
  name?: string;
  description?: string;
  parameters?: string;
  code?: string;
  chatId?: string;
}

export interface UpdateForm {
  airtableLogsBase?: string;
  assistantEmail?: string;
  canAcceptVoiceMessages?: boolean;
  colors?: string;
  dataToExtract?: ExtractedDataField[];
  direction?: Direction;
  dynamicSystemPrompts?: DynamicSystemPrompt[];
  firstMessage?: string;
  functions?: ChatFunction[];
  messagesInMemory?: number;
  model?: ChatModel;
  modelMetadataId?: number;
  name?: string;
  openaiKey?: string;
  promptVariables?: PromptVariable[];
  sendgridApiKey?: string;
  showSources?: boolean;
  systemPrompt?: string;
  temperature?: number;
  textFieldLabel?: string;
  ttsPrompt?: string;
  twilioAccountSid?: string;
  twilioAuthToken?: string;
  twilioMessagingServiceSid?: string;
  whatsappPrompt?: string;
  answersModality?: AnswersModality;
}
