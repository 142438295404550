import { useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AnswersModality,
  ChatFunction,
  Direction,
  DynamicSystemPrompt,
  ModelMetadata,
  PromptVariable,
  Role,
  UpdateForm,
} from 'types';
import { fetchWithAuth } from 'utils';
import { apiUrl } from 'utils/constants';
import { AuthContext } from 'contexts';
import {
  DynamicSystemPromptRepeater,
  ExtractedDataFieldRepeater,
  FunctionRepeater,
} from 'components';

import { Card, CardContent, CardFooter } from 'components/shadcn/card';
import { Button } from 'components/shadcn/button';
import { Input } from 'components/shadcn/input';
import { Label } from 'components/shadcn/label';
import { Checkbox } from 'components/shadcn/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/select';
import {
  Dialog,
  DialogContent,
  DialogClose,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/dialog';
import { X } from 'lucide-react';

export const UpdateChatForm = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDynamicPromptDialogOpen, setIsDynamicPromptDialogOpen] = useState(false);
  const [modelOptions, setModelOptions] = useState<ModelMetadata[]>([]);
  const { id } = useParams();
  const query = new URLSearchParams(location.search);
  const duplicateChatId = query.get('duplicateChatId');
  const navigate = useNavigate();
  const { role, canEditDynamicPrompts } = useContext(AuthContext);
  const isAdmin = role === Role.ADMIN;

  const methods = useForm({
    defaultValues: {
      assistantEmail: '',
      answersModality: AnswersModality.TEXT,
      name: '',
      systemPrompt: '',
      dynamicSystemPrompts: [],
      whatsappPrompt: '',
      firstMessage: '',
      modelId: 0,
      canAcceptVoiceMessages: false,
      direction: Direction.RTL,
      textFieldLabel: '',
      openaiKey: '',
      twilioAccountSid: '',
      twilioAuthToken: '',
      twilioMessagingServiceSid: '',
      sendgridApiKey: '',
      airtableLogsBase: '',
      colors: '{}',
      temperature: 0.2,
      messagesInMemory: 40,
      showSources: false,
      functions: [],
      ttsPrompt: '',
      promptVariables: [],
      dataToExtract: [],
    },
  });

  useEffect(() => {
    // Fetch available models for the dropdown
    const fetchModels = async () => {
      try {
        const response = await fetchWithAuth(`${apiUrl}/model-metadata`);
        if (response.ok) {
          const models = await response.json();
          setModelOptions(models.filter((model: ModelMetadata) => !model.isArchived));
        }
      } catch (error) {
        console.error('Error fetching models:', error);
      }
    };

    fetchModels();
  }, []);

  const processChatFunctions = async (functions: ChatFunction[]) => {
    for (const f of functions) {
      if (f.id) {
        await fetchWithAuth(`${apiUrl}/chat-function/${f.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(f),
        });
      } else {
        f.chatId = id;
        const response = await fetchWithAuth(`${apiUrl}/chat-function`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(f),
        });
        if (response.status !== 200) {
          throw new Error('Something went wrong!');
        }
      }
    }
  };

  const formPropsHandler = (data: UpdateForm) => {
    data.functions?.forEach((f) => {
      f.parameters = f.parameters ? JSON.parse(f.parameters as string) : {};
    });
    data.dynamicSystemPrompts = data.dynamicSystemPrompts?.map((d) => ({
      id: Number(d.id ?? -1),
      name: d.name,
      condition: d.condition,
      prompt: d.prompt,
      zIndex: Number(d.zIndex),
    }));
    data.promptVariables = data.promptVariables?.map((v) => ({
      id: Number(v.id ?? -1),
      name: v.name,
      value: v.value,
      injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
      injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
      injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
      injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
    }));
    data.dataToExtract = data.dataToExtract?.map((f) => ({
      id: Number(f.id ?? -1),
      name: f.name,
      description: f.description,
      dataType: f.dataType,
    }));
    data.temperature = Number(data.temperature);
    data.messagesInMemory = Number(data.messagesInMemory);
    data.colors = JSON.parse(data.colors ?? '{}');
    data.showSources =
      typeof data.showSources === 'string' ? JSON.parse(data.showSources) : data.showSources;
  };

  const onSubmit: SubmitHandler<UpdateForm> = async (data) => {
    formPropsHandler(data);
    try {
      const functions = data.functions;
      delete data.functions;
      if (id) {
        data.dynamicSystemPrompts = {
          deleteMany: { createdAt: { lt: new Date() } },
          upsert: data.dynamicSystemPrompts?.map((d: DynamicSystemPrompt) => ({
            where: { id: d.id },
            update: {
              condition: d.condition,
              prompt: d.prompt,
              name: d.name,
              zIndex: d.zIndex,
            },
            create: {
              id: undefined,
              name: d.name,
              condition: d.condition,
              prompt: d.prompt,
              zIndex: d.zIndex,
            },
          })),
        } as never;
        data.promptVariables = {
          deleteMany: { createdAt: { lt: new Date() } },
          upsert: data.promptVariables?.map((v: PromptVariable) => ({
            where: { id: v.id },
            update: {
              name: v.name,
              value: v.value,
              injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
              injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
              injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
              injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
            },
            create: {
              id: undefined,
              name: v.name,
              value: v.value,
              injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
              injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
              injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
              injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
            },
          })),
        } as never;
        data.dataToExtract = {
          deleteMany: { createdAt: { lt: new Date() } },
          upsert: data.dataToExtract?.map((f) => ({
            where: { id: f.id },
            update: {
              name: f.name,
              description: f.description,
              dataType: f.dataType,
            },
            create: {
              id: undefined,
              name: f.name,
              description: f.description,
              dataType: f.dataType,
            },
          })),
        } as never;
        const response = await fetchWithAuth(`${apiUrl}/chat-metadata/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (response.status !== 200) {
          throw new Error('Something went wrong!');
        }
      } else {
        data.dynamicSystemPrompts = {
          create: data.dynamicSystemPrompts?.map((d: DynamicSystemPrompt) => ({
            id: undefined,
            condition: d.condition,
            prompt: d.prompt,
            zIndex: d.zIndex,
          })),
        } as never;
        data.promptVariables = {
          create: data.promptVariables?.map((v: PromptVariable) => ({
            id: undefined,
            name: v.name,
            value: v.value,
            injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
            injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
            injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
            injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
          })),
        } as never;
        data.dataToExtract = {
          create: data.dataToExtract?.map((f) => ({
            id: undefined,
            name: f.name,
            description: f.description,
            dataType: f.dataType,
          })),
        } as never;
        const response = await fetchWithAuth(`${apiUrl}/chat-metadata`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const newChat = await response.json();
        navigate(`/dashboard/edit/${newChat.id}`);
      }

      if (functions) {
        await processChatFunctions(functions);
      }
      fetchChatMetadata();
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error Submitting data:', error);
    }
  };

  const fetchChatMetadata = async () => {
    if (!id && !duplicateChatId) return; // Skip fetching if creating a new chatbot
    const chatId = duplicateChatId || id;
    try {
      const response = await fetchWithAuth(`${apiUrl}/chat-metadata/${chatId}`);
      const jsonData = await response.json();
      methods.setValue('name', `${duplicateChatId ? 'Copy of ' : ''}${jsonData.name}`);
      methods.setValue('textFieldLabel', jsonData.textFieldLabel);
      methods.setValue('systemPrompt', jsonData.systemPrompt);
      methods.setValue('dynamicSystemPrompts', jsonData.dynamicSystemPrompts);
      methods.setValue('whatsappPrompt', jsonData.whatsappPrompt);
      methods.setValue('firstMessage', jsonData.firstMessage);
      methods.setValue('modelId', jsonData.modelMetadata?.id || 0);
      methods.setValue('canAcceptVoiceMessages', jsonData.canAcceptVoiceMessages);
      methods.setValue('answersModality', jsonData.answersModality);
      methods.setValue('direction', jsonData.direction);
      methods.setValue('colors', JSON.stringify(jsonData.colors));
      methods.setValue('temperature', jsonData.temperature);
      methods.setValue('showSources', jsonData.showSources);
      methods.setValue('openaiKey', jsonData.openaiKey);
      methods.setValue('twilioAccountSid', jsonData.twilioAccountSid);
      methods.setValue('twilioAuthToken', jsonData.twilioAuthToken);
      methods.setValue('twilioMessagingServiceSid', jsonData.twilioMessagingServiceSid);
      methods.setValue('assistantEmail', jsonData.assistantEmail);
      methods.setValue('sendgridApiKey', jsonData.sendgridApiKey);
      methods.setValue('airtableLogsBase', jsonData.airtableLogsBase);
      methods.setValue('messagesInMemory', jsonData.messagesInMemory);
      methods.setValue('ttsPrompt', jsonData.ttsPrompt);
      methods.setValue('promptVariables', jsonData.promptVariables);
      methods.setValue('dataToExtract', jsonData.dataToExtract);

      jsonData.functions?.forEach((f: ChatFunction) => {
        f.parameters = JSON.stringify(f.parameters);
      });
      methods.setValue('functions', jsonData.functions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchChatMetadata();
  }, [id]);

  return (
    <FormProvider {...methods}>
      <form className="space-y-6" onSubmit={methods.handleSubmit(onSubmit)}>
        <Card className="shadow-xl rounded-xl">
          <CardContent className="p-6 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {isAdmin && (
                <>
                  <div className="col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="name">
                        Name
                      </Label>
                      <Controller
                        name="name"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input
                              id="name"
                              {...field}
                              className="w-full"
                              placeholder="Enter chatbot name"
                            />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="modelId">
                        Model
                      </Label>
                      <Controller
                        name="modelId"
                        control={methods.control}
                        render={({ field }) => (
                          <Select
                            value={field.value.toString()}
                            onValueChange={(value) => field.onChange(Number(value))}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select a model" />
                            </SelectTrigger>
                            <SelectContent>
                              {modelOptions.map((model) => (
                                <SelectItem key={model.id} value={model.id.toString()}>
                                  {model.displayName}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center">
                    <Controller
                      name="canAcceptVoiceMessages"
                      control={methods.control}
                      render={({ field }) => (
                        <div className="flex items-center space-x-2">
                          <Checkbox
                            id="acceptVoice"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <Label variant="slate" htmlFor="acceptVoice">
                            Can Accept Voice Messages
                          </Label>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="answersModality">
                        Answers Modality
                      </Label>
                      <Controller
                        name="answersModality"
                        control={methods.control}
                        render={({ field }) => (
                          <Select value={field.value} onValueChange={field.onChange}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select answer modality" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value={AnswersModality.TEXT}>Text</SelectItem>
                              <SelectItem value={AnswersModality.VOICE}>Voice</SelectItem>
                              <SelectItem value={AnswersModality.TEXT_AND_VOICE}>
                                Text and Voice
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="ttsPrompt">
                        TTS Prompt
                      </Label>
                      <Controller
                        name="ttsPrompt"
                        control={methods.control}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            className="flex min-h-[120px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                            placeholder="Enter TTS prompt"
                            rows={4}
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-span-2">
                <div className="space-y-2">
                  <Label variant="slate" htmlFor="systemPrompt">
                    System Prompt
                  </Label>
                  <Controller
                    name="systemPrompt"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="space-y-1">
                        <textarea
                          {...field}
                          className="flex min-h-[300px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                          placeholder="Enter system prompt"
                          rows={20}
                        />
                        {error && <p className="text-sm text-destructive">{error.message}</p>}
                      </div>
                    )}
                  />
                </div>
              </div>

              {canEditDynamicPrompts && (
                <div className="col-span-2 -mt-4">
                  <Button
                    variant="secondary"
                    className="w-full"
                    onClick={() => setIsDynamicPromptDialogOpen(true)}
                  >
                    ⑆ Dynamic Prompts
                  </Button>
                </div>
              )}

              <div className="col-span-2">
                <div className="space-y-2">
                  <Label variant="slate" htmlFor="whatsappPrompt">
                    Whatsapp Prompt
                  </Label>
                  <Controller
                    name="whatsappPrompt"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="space-y-1">
                        <textarea
                          {...field}
                          className="flex min-h-[150px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                          placeholder="Enter whatsapp prompt"
                          rows={6}
                        />
                        {error && <p className="text-sm text-destructive">{error.message}</p>}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="col-span-2">
                <div className="space-y-2">
                  <Label variant="slate" htmlFor="firstMessage">
                    First Message
                  </Label>
                  <Controller
                    name="firstMessage"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="space-y-1">
                        <textarea
                          {...field}
                          className="flex min-h-[120px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                          placeholder="Enter first message"
                          rows={4}
                        />
                        {error && <p className="text-sm text-destructive">{error.message}</p>}
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="col-span-2">
                <div className="space-y-2">
                  <Label variant="slate" htmlFor="textFieldLabel">
                    Text Field Label
                  </Label>
                  <Controller
                    name="textFieldLabel"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                      <div className="space-y-1">
                        <Input id="textFieldLabel" {...field} className="w-full" />
                        {error && <p className="text-sm text-destructive">{error.message}</p>}
                      </div>
                    )}
                  />
                </div>
              </div>

              {isAdmin && (
                <>
                  <div className="col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="direction">
                        Direction
                      </Label>
                      <Controller
                        name="direction"
                        control={methods.control}
                        render={({ field }) => (
                          <Select value={field.value} onValueChange={field.onChange}>
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select direction" />
                            </SelectTrigger>
                            <SelectContent>
                              {Object.values(Direction).map((dir) => (
                                <SelectItem key={dir} value={dir}>
                                  {dir}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="colors">
                        Colors
                      </Label>
                      <Controller
                        name="colors"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input id="colors" {...field} className="w-full" />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-1 md:col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="temperature">
                        Temperature
                      </Label>
                      <Controller
                        name="temperature"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input id="temperature" {...field} className="w-full" />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-1 md:col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="showSources">
                        Show Sources
                      </Label>
                      <Controller
                        name="showSources"
                        control={methods.control}
                        render={({ field }) => (
                          <Select
                            value={field.value.toString()}
                            onValueChange={(value) => field.onChange(value)}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="Select option" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="true">True</SelectItem>
                              <SelectItem value="false">False</SelectItem>
                            </SelectContent>
                          </Select>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-1 md:col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="messagesInMemory">
                        Messages In Memory
                      </Label>
                      <Controller
                        name="messagesInMemory"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input
                              id="messagesInMemory"
                              {...field}
                              className="w-full"
                              type="number"
                            />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  {/* API Keys */}
                  <div className="col-span-2">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="openaiKey">
                        OpenAI Key
                      </Label>
                      <Controller
                        name="openaiKey"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input
                              id="openaiKey"
                              {...field}
                              className="w-full"
                              type="password"
                              autoComplete="off"
                            />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="twilioAccountSid">
                        Twilio Account SID
                      </Label>
                      <Controller
                        name="twilioAccountSid"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input id="twilioAccountSid" {...field} className="w-full" />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="twilioAuthToken">
                        Twilio Auth Token
                      </Label>
                      <Controller
                        name="twilioAuthToken"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input
                              id="twilioAuthToken"
                              {...field}
                              className="w-full"
                              type="password"
                              autoComplete="off"
                            />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="twilioMessagingServiceSid">
                        Twilio Messaging Service SID
                      </Label>
                      <Controller
                        name="twilioMessagingServiceSid"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input id="twilioMessagingServiceSid" {...field} className="w-full" />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="sendgridApiKey">
                        Sendgrid API Key
                      </Label>
                      <Controller
                        name="sendgridApiKey"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input
                              id="sendgridApiKey"
                              {...field}
                              className="w-full"
                              type="password"
                            />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="space-y-2">
                      <Label variant="slate" htmlFor="assistantEmail">
                        Assistant Email
                      </Label>
                      <Controller
                        name="assistantEmail"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input id="assistantEmail" {...field} className="w-full" type="email" />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="space-y-2">
                      <Label
                        variant="slate"
                        htmlFor="airtableLogsBase"
                        className="flex items-center"
                      >
                        <span className="mr-2">📊</span> Airtable Logs Base
                      </Label>
                      <Controller
                        name="airtableLogsBase"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="space-y-1">
                            <Input
                              id="airtableLogsBase"
                              {...field}
                              className="w-full transition-all hover:border-blue-300 focus:ring-2 focus:ring-blue-200"
                            />
                            {error && <p className="text-sm text-destructive">{error.message}</p>}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-4 rounded-lg border border-blue-100 shadow-sm">
                      <h2 className="text-indigo-700 text-xl font-medium mb-3 flex items-center">
                        <span className="bg-indigo-100 p-1.5 rounded-lg mr-2">🤖</span>
                        Functions
                        <span className="text-sm text-indigo-500 ml-2 font-normal">
                          (Special abilities for your bot)
                        </span>
                      </h2>
                      <FunctionRepeater />
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="bg-gradient-to-r from-emerald-50 to-teal-50 p-4 rounded-lg border border-emerald-100 shadow-sm mt-3">
                      <h2 className="text-emerald-700 text-xl font-medium mb-3 flex items-center">
                        <span className="bg-emerald-100 p-1.5 rounded-lg mr-2">📝</span>
                        Data To Extract
                        <span className="text-sm text-emerald-500 ml-2 font-normal">
                          (Information to capture)
                        </span>
                      </h2>
                      <ExtractedDataFieldRepeater />
                    </div>
                  </div>
                </>
              )}
            </div>
          </CardContent>
          <CardFooter className="flex justify-center p-6 bg-gradient-to-r from-slate-50 to-gray-50 rounded-b-xl">
            <Button
              type="submit"
              variant="secondary"
              className="px-8 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 text-white shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 font-medium rounded-lg"
            >
              ✨ Save Changes ✨
            </Button>
          </CardFooter>
        </Card>
      </form>

      {snackbarOpen && (
        <div
          className="fixed bottom-4 right-4 bg-green-50 border border-green-200 shadow-lg rounded-md p-4 z-50 animate-in slide-in-from-right duration-300"
          style={{
            animation: 'bounce-in 0.5s ease-out',
          }}
        >
          <div className="flex items-center gap-2">
            <span className="text-green-700 flex items-center">Chat Updated Successfully! 🎉</span>
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0 hover:bg-green-100 hover:text-green-800 transition-all"
              onClick={() => setSnackbarOpen(false)}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
          <style>{`
            @keyframes bounce-in {
              0% {
                transform: translateX(100px);
                opacity: 0;
              }
              50% {
                transform: translateX(-15px);
              }
              75% {
                transform: translateX(5px);
              }
              100% {
                transform: translateX(0);
                opacity: 1;
              }
            }
          `}</style>
        </div>
      )}

      <Dialog open={isDynamicPromptDialogOpen} onOpenChange={setIsDynamicPromptDialogOpen}>
        <DialogContent className="sm:max-w-3xl max-h-[90vh] overflow-y-auto">
          <DialogHeader>
            <DialogTitle>Dynamic System Prompts</DialogTitle>
          </DialogHeader>
          <DynamicSystemPromptRepeater />
          <DialogClose />
        </DialogContent>
      </Dialog>
    </FormProvider>
  );
};
